jQuery(document).ready(function($){
    //Add animation to avery title an image in the page
    $('figure:not(".fadeUp"), .video-thumb, .f4s-card, .use-card').addClass('wow animate__animated animate__zoomIn');
    $('h2, h3, h4, h5, h6, .number, .fadeUp').addClass('wow animate__animated animate__fadeInUp');

    //Forms
    $('.forminator-field--phone').attr('inputmode', 'tel');
    $('.street-no input, .zip-code input').attr('inputmode', 'numeric');

    $('.forminator-multiselect .forminator-option').each(function(){
        if($(this).find('input').val() == 'hidden'){
            $(this).find('input').prop("checked", false);
            $(this).css('display', 'none');
        }
    });
     
    new WOW().init();
    
    //functionality for multiple carousel indicators
    $('.carousel').on('slid.bs.carousel', function() {
        $(".carousel-indicators-desktop li").removeClass("active");
        indicators = $(".carousel-indicators li.active").data("slide-to");
        a = $(".carousel-indicators-desktop").find("[data-slide-to='" + indicators + "']").addClass("active");
    });

    //Makes text col equals the height of the image col (imgTextLr post type)
    function imgTextLr_height(){
        var mw = window.matchMedia( "(max-width: 1199px)" );
        if(mw.matches){
            var height = $('#imgTextLr .image img').height();
            console.log(height);
            $('#imgTextLr .text').css('min-height', height);
        }else{
            $('#imgTextLr .text').css('min-height', 'auto');
        }
    }
    
    //On window resize
    $(window).on('resize', function(){
        imgTextLr_height();
    });

    //Fires height function on lazy load
    $('#imgTextLr .image img').on('load',function(){
        imgTextLr_height();
    });

    //Fixing open modal scrol on apple devices
    var previousScrollY = 0;
    $('#videoModal').on('show.bs.modal', function () {
        previousScrollY = window.scrollY;
        $('body').addClass('freeze').css({
            marginTop: -previousScrollY,
        });
    });

    $('#videoModal .close-btn').on('click', function(){
        $('#videoModal').modal('hide');
    });

    //Removing video from modal on close
    $('#videoModal').on('hide.bs.modal', function () {
        $('#videoModal .modal-content').find('.video-wrap').remove();
        $('body').removeClass('freeze').css({
            marginTop: 0,
        });
        window.scrollTo(0, previousScrollY);
    });

    //Adding video on modal
    if($('.video-thumb')){
        $('.video-thumb').on('click', function(){
            var video = $(this).data('video');
            if(video !== ''){
                $('#videoModal .modal-content').append('<div class="video-wrap"><div class="video"><iframe allow="fullscreen;" src="' + video + '"></iframe></div></div>')
                $('#videoModal').modal('show'); 
            }
        });
    }

    //imgTextLr_height()
});